import React, { useState, useEffect } from "react";
import "./homePage.scss";
import specialOfferImg from "../../assets/home/special-offer.png";
import callingsvg from "../../assets/home/calling.svg";
import locationsvg from "../../assets/home/location.svg";
import group1 from "../../assets/home/Group-box-1.png";
import group2 from "../../assets/home/Group-box-2.png";
import group3 from "../../assets/home/Group-box-3.png";
import messagesvg from "../../assets/home/message.svg";
import servicesvg1 from "../../assets/home/Invisible-service.svg";
import servicesvg2 from "../../assets/home/skip-installation.svg";
import servicesvg3 from "../../assets/home/works-on-al-phones.svg";
import servicesvg4 from "../../assets/home/ios-android.svg";
import plusIcon from "../../assets/home/plus.svg";
import minusIcon from "../../assets/home/minus.svg";
import bgBox1 from "../../assets/home/bg_box1.svg";
import bgBox2 from "../../assets/home/bg_box2.svg";
import bgBox3 from "../../assets/home/bg_box3.svg";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import { Link, useSearchParams } from "react-router-dom";
import PageBannerContant from "../pageBanner";
import { useAppContextInput } from "../../App";
import { Skeleton } from "antd";
import useScrollToTop from "../customHook/useScrollToTop";

const HomePageComponent = (props) => {
  const { productList, contant, from } = props;
  const { t } = useTranslation();
  const cookies = new Cookies();
  const [collapseTab, setCollapseTab] = useState(0);
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const { fx } = searchNumber;
  const utm_term = fx?.replaceAll("-", " ");

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useScrollToTop();

  const cur = cookies.get("currency");
  const lang = cookies.get("lang");
  const [pricing, setPricing] = useState(cur);
  useEffect(() => {
    if (cur) {
      setPricing(cur);
    }
  }, [cur]);
  const langCheck = (lang === "ar" ||lang === "he");

  const trialProduct = productList?.data.find((item) => item.title === "trial");
  const findPrice = trialProduct?.currency_options.find(
    (item) => item.parameter === pricing
  );

  const subscriptionProduct = productList?.data.find(
    (item) => item.title === "standard"
  );
  const subscriptionCurr = subscriptionProduct?.currency_options.find(
    (item) => item.parameter === pricing
  );
  const inputs = document.getElementsByClassName("form-control");
  if (inputs && inputs.length > 0) {
    inputs[0] && (inputs[0].disabled = true);
    inputs[1] && (inputs[1].disabled = true);
  }
  const { inputRef, mobileInputRef, handleInputFocus, plan } =
    useAppContextInput();
  // Auto default focus on phone field
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div className="homePage-main-section">
      <div className="bg-grad-section">
        {contant !== "none" && (
          <PageBannerContant
            from="none"
            inputRef={inputRef}
            mobileInputRef={mobileInputRef}
            plan={plan}
          />
        )}
        <div className="home-special-offer">
          <div className="specialOffer-container">
            <div className="special-offerbox-partition">
              <div className="img-box">
                <div className="img-box-text">
                  <div className="box-heading main-title">
                    <p>{t("PRICING_SPECIAL_OFFER")}</p>
                  </div>
                  <div className="desc-special">
                    <p>
                      {t("PRICING_OFFER", {
                        price: findPrice?.symbol + findPrice?.amount,
                      })}
                    </p>
                  </div>
                  <div className="desc-special-span">
                    <p>{t("PRICING_TEXT")}</p>
                  </div>
                </div>
                <div className="backGroundImg2">
                  <img src={specialOfferImg} alt="BackGround" />
                </div>
              </div>
              <div className="right-content">
                <div className="pricing-title"></div>
                <div className="img-box-2">
                  <div className="box-heading">
                    <p>{t("PRICING_TRIAL_TAG")}</p>
                  </div>
                  <div className="desc-special">
                    <h3>
                      {findPrice ? (
                        findPrice?.symbol + findPrice?.amount
                      ) : (
                        <Skeleton.Button active={true} size={"large"} />
                      )}
                    </h3>
                    <p>{t("PRICING_TRIAL_TIME")}</p>
                  </div>
                  <div className="desc-special-span">
                    <p>
                      {t("PRICING_TRIAL_TEXT", {
                        price: findPrice?.symbol + subscriptionCurr?.amount,
                      })}
                    </p>
                  </div>
                  <div className="bottom-btn">
                    <Link
                      to={`#`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleInputFocus("trial");
                        handleScrollTop();
                      }}
                    >
                      <button className="hl_cta_wrap">
                        {t("PRICING_TRIAL_START")}
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="img-box-3">
                  <div className="box-heading">
                    <p>{t("PRICING_SUBSCRIPTION_TAG")}</p>
                  </div>
                  <div className="desc-special">
                    <h3>
                      {findPrice ? (
                        findPrice?.symbol + subscriptionCurr?.amount
                      ) : (
                        <Skeleton.Button active={true} size={"large"} />
                      )}
                    </h3>
                    <p>{t("PRICING_SUBSCRIPTION_TIME")}</p>
                  </div>
                  <div className="desc-special-span">
                    <p>{t("PRICING_SUBSCRIPTION_TEXT")}</p>
                  </div>
                  <div className="bottom-btn">
                    <Link
                      to={`#`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleInputFocus("trial");
                        handleScrollTop();
                      }}
                    >
                      <button className="hl_cta_wrap-white">
                        {t("PRICING_SUBSCRIPTION_START")}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="how-work-section">
          <div className="how-work-container">
            <div className="how-work-title">
              <p>{t("HOW_IT_WORK_TITLE")}</p>
            </div>
            <div className="how-work-body">
              <div className="how-work-desc-sections">
                <div className="box group-box-1">
                  <img
                    src={langCheck ? bgBox1 : group1}
                    alt=""
                    className="bgboximg"
                  />
                  <div className="how-work-contant">
                    <div className="left-contant">
                      {" "}
                      <div className="how-work-svg">
                        <img src={callingsvg} alt="Number" />
                      </div>
                      <div className="how-work-box-title">
                        <p>{t("HOW_IT_WORK_TAG_1_TITLE")}</p>
                      </div>
                      <div className="how-work-desc">
                        <p>{t("HOW_IT_WORK_TAG_1_TEXT")}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box group-box-2">
                  <img
                    src={langCheck ? bgBox2 : group2}
                    alt=""
                    className="bgboximg"
                  />
                  <div className="how-work-contant">
                    <div className="left-contant">
                      {" "}
                      <div className="how-work-svg">
                        <img src={messagesvg} alt="Number" />
                      </div>
                      <div className="how-work-box-title">
                        <p>{t("HOW_IT_WORK_TAG_2_TITLE")}</p>
                      </div>
                      <div className="how-work-desc">
                        <p>{t("HOW_IT_WORK_TAG_2_TEXT")}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box group-box-3">
                  <img
                    src={langCheck ? bgBox3 : group3}
                    alt=""
                    className="bgboximg"
                  />
                  <div className="how-work-contant">
                    <div className="left-contant">
                      {" "}
                      <div className="how-work-svg">
                        <img type="image" src={locationsvg} alt="Number" />
                      </div>
                      <div className="how-work-box-title">
                        <p>
                          {from === "track" || from === "trace"
                            ? utm_term
                              ? utm_term.charAt(0).toUpperCase() +
                                utm_term.slice(1, 1111)
                              : t("HOW_IT_WORK_TAG_3_TITLE")
                            : t("HOW_IT_WORK_TAG_3_TITLE")}
                        </p>
                      </div>
                      <div className="how-work-desc">
                        <p>{t("HOW_IT_WORK_TAG_3_TEXT")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="choose-tracelo-section">
        <div className="choose-tracelo-container">
          <div className="wrap-choose-tracelo">
            <div className="choose-tracelo-title">
              <p>{t("WHY_CHOOSE_TITLE")}</p>
            </div>
            <div className="choose-tracelo-desc">
              <p>{t("WHY_CHOOSE_DESCRIPTION")}</p>
            </div>
            <div className="choose-tracelo-under-section">
              <div className="df-wrap-service">
                <div className="choose-tracelo-box">
                  <div className="choose-tracelo-part">
                    <div className="choose-tracelo-tag">
                      <img
                        src={servicesvg1}
                        alt="“Invisible” service"
                        width={110}
                        height={110}
                      />
                    </div>
                    <div className="choose-tracelo-box-title">
                      <p>{t("WHY_CHOOSE_TAG_1_TITLE")}</p>
                    </div>
                    <div className="choose-tracelo-box-desc">
                      <p>{t("WHY_CHOOSE_TAG_1_TEXT")}</p>
                    </div>
                  </div>
                </div>
                <div className="choose-tracelo-box">
                  <div className="choose-tracelo-part">
                    <div className="choose-tracelo-tag">
                      <img
                        src={servicesvg2}
                        alt="Skip installation"
                        width={110}
                        height={110}
                      />
                    </div>
                    <div className="choose-tracelo-box-title">
                      <p>{t("WHY_CHOOSE_TAG_2_TITLE")}</p>
                    </div>
                    <div className="choose-tracelo-box-desc">
                      <p>{t("WHY_CHOOSE_TAG_2_TEXT")}</p>
                    </div>
                  </div>
                </div>
                <div className="choose-tracelo-box">
                  <div className="choose-tracelo-part">
                    <div className="choose-tracelo-tag">
                      <img
                        src={servicesvg3}
                        alt="Works on al phones and carriers"
                        width={110}
                        height={110}
                      />
                    </div>
                    <div className="choose-tracelo-box-title">
                      <p>{t("WHY_CHOOSE_TAG_3_TITLE")}</p>
                    </div>
                    <div className="choose-tracelo-box-desc">
                      <p>{t("WHY_CHOOSE_TAG_3_TEXT")}</p>
                    </div>
                  </div>
                </div>
                <div className="choose-tracelo-box">
                  <div className="choose-tracelo-part">
                    <div className="choose-tracelo-tag">
                      <img
                        src={servicesvg4}
                        alt="iOS and Android compatible"
                        width={110}
                        height={110}
                      />
                    </div>
                    <div className="choose-tracelo-box-title">
                      <p>{t("WHY_CHOOSE_TAG_4_TITLE")}</p>
                    </div>
                    <div className="choose-tracelo-box-desc">
                      <p>{t("WHY_CHOOSE_TAG_4_TEXT")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="locate-btn">
              <button className="hl_cta_wrap" onClick={handleScrollTop}>
                {from === "track" || from === "trace"
                  ? utm_term
                    ? utm_term.charAt(0).toUpperCase() + utm_term.slice(1, 1111)
                    : t("WHY_CHOOSE_LOCATE_BUTTON")
                  : t("WHY_CHOOSE_LOCATE_BUTTON")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-grd-section">
        <div className="frequently-asked-question">
          <div className="question-title">
            <p>{t("FAQ_ASKED_QUESTION_TITLE")}</p>
            <span>{t("FAQ_ASKED_QUESTION_DESCRIPTION")}</span>
          </div>
          <div className="question-collapse">
            <div
              className="collapse-box"
              onClick={() => setCollapseTab(collapseTab === 1 ? 0 : 1)}
            >
              <div className="collapse-box-df-title">
                <div className="collapse-box-title">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_1_TITLE")}</p>
                </div>
                <div className="collapse-box-icon">
                  {collapseTab === 1 ? (
                    <div>
                      <img src={minusIcon} alt="Minus" />
                    </div>
                  ) : (
                    <div>
                      <img src={plusIcon} alt="Plus" />
                    </div>
                  )}
                </div>
              </div>
              {collapseTab === 1 && (
                <div className="open-tab-desc">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_1_TEXT")}</p>
                </div>
              )}
            </div>
            <div
              className="collapse-box"
              onClick={() => setCollapseTab(collapseTab === 2 ? 0 : 2)}
            >
              <div className="collapse-box-df-title">
                <div className="collapse-box-title">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_2_TITLE")}</p>
                </div>
                <div className="collapse-box-icon">
                  {collapseTab === 2 ? (
                    <div>
                      <img src={minusIcon} alt="Minus" />
                    </div>
                  ) : (
                    <div>
                      <img src={plusIcon} alt="Plus" />
                    </div>
                  )}
                </div>
              </div>
              {collapseTab === 2 && (
                <div className="open-tab-desc">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_2_TEXT")}</p>
                </div>
              )}
            </div>
            <div
              className="collapse-box"
              onClick={() => setCollapseTab(collapseTab === 3 ? 0 : 3)}
            >
              <div className="collapse-box-df-title">
                <div className="collapse-box-title">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_3_TITLE")}</p>
                </div>
                <div className="collapse-box-icon">
                  {collapseTab === 3 ? (
                    <div>
                      <img src={minusIcon} alt="Minus" />
                    </div>
                  ) : (
                    <div>
                      <img src={plusIcon} alt="Plus" />
                    </div>
                  )}
                </div>
              </div>
              {collapseTab === 3 && (
                <div className="open-tab-desc">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_3_TEXT")}</p>
                </div>
              )}
            </div>
            <div
              className="collapse-box"
              onClick={() => setCollapseTab(collapseTab === 4 ? 0 : 4)}
            >
              <div className="collapse-box-df-title">
                <div className="collapse-box-title">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_4_TITLE")}</p>
                </div>
                <div className="collapse-box-icon">
                  {collapseTab === 4 ? (
                    <div>
                      <img src={minusIcon} alt="Minus" />
                    </div>
                  ) : (
                    <div>
                      <img src={plusIcon} alt="Plus" />
                    </div>
                  )}
                </div>
              </div>
              {collapseTab === 4 && (
                <div className="open-tab-desc">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_4_TEXT")}</p>
                </div>
              )}
            </div>
            <div
              className="collapse-box"
              onClick={() => setCollapseTab(collapseTab === 5 ? 0 : 5)}
            >
              <div className="collapse-box-df-title">
                <div className="collapse-box-title">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_5_TITLE")}</p>
                </div>
                <div className="collapse-box-icon">
                  {collapseTab === 5 ? (
                    <div>
                      <img src={minusIcon} alt="Minus" />
                    </div>
                  ) : (
                    <div>
                      <img src={plusIcon} alt="Plus" />
                    </div>
                  )}
                </div>
              </div>

              {collapseTab === 5 && (
                <div className="open-tab-desc">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_5_TEXT")}</p>
                </div>
              )}
            </div>
            <div
              className="collapse-box"
              onClick={() => setCollapseTab(collapseTab === 6 ? 0 : 6)}
            >
              <div className="collapse-box-df-title">
                <div className="collapse-box-title">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_6_TITLE")}</p>
                </div>
                <div className="collapse-box-icon">
                  {collapseTab === 6 ? (
                    <div>
                      <img src={minusIcon} alt="Minus" />
                    </div>
                  ) : (
                    <div>
                      <img src={plusIcon} alt="Plus" />
                    </div>
                  )}
                </div>
              </div>
              {collapseTab === 6 && (
                <div className="open-tab-desc">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_6_TEXT")}</p>
                </div>
              )}
            </div>
            <div
              className="collapse-box"
              onClick={() => setCollapseTab(collapseTab === 7 ? 0 : 7)}
            >
              <div className="collapse-box-df-title">
                <div className="collapse-box-title">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_7_TITLE")}</p>
                </div>
                <div className="collapse-box-icon">
                  {collapseTab === 7 ? (
                    <div>
                      <img src={minusIcon} alt="Minus" />
                    </div>
                  ) : (
                    <div>
                      <img src={plusIcon} alt="Plus" />
                    </div>
                  )}
                </div>
              </div>
              {collapseTab === 7 && (
                <div className="open-tab-desc">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_7_TEXT")}</p>
                </div>
              )}
            </div>
            <div
              className="collapse-box"
              onClick={() => setCollapseTab(collapseTab === 8 ? 0 : 8)}
            >
              <div className="collapse-box-df-title">
                <div className="collapse-box-title">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_8_TITLE")}</p>
                </div>
                <div className="collapse-box-icon">
                  {collapseTab === 8 ? (
                    <div>
                      <img src={minusIcon} alt="Minus" />
                    </div>
                  ) : (
                    <div>
                      <img src={plusIcon} alt="Plus" />
                    </div>
                  )}
                </div>
              </div>
              {collapseTab === 8 && (
                <div className="open-tab-desc">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_8_TEXT")}</p>
                </div>
              )}
            </div>
            <div
              className="collapse-box"
              onClick={() => setCollapseTab(collapseTab === 9 ? 0 : 9)}
            >
              <div className="collapse-box-df-title">
                <div className="collapse-box-title">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_9_TITLE")}</p>
                </div>
                <div className="collapse-box-icon">
                  {collapseTab === 9 ? (
                    <div>
                      <img src={minusIcon} alt="Minus" />
                    </div>
                  ) : (
                    <div>
                      <img src={plusIcon} alt="Plus" />
                    </div>
                  )}
                </div>
              </div>
              {collapseTab === 9 && (
                <div className="open-tab-desc">
                  <p>
                    {t("FAQ_ASKED_QUESTION_TAB_9_TEXT", {
                      trial_price: findPrice.symbol + findPrice.amount,
                      standart_price:
                        subscriptionCurr.symbol + subscriptionCurr.amount,
                    })}
                  </p>
                </div>
              )}
            </div>
            <div
              className="collapse-box"
              onClick={() => setCollapseTab(collapseTab === 10 ? 0 : 10)}
            >
              <div className="collapse-box-df-title">
                <div className="collapse-box-title">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_10_TITLE")}</p>
                </div>
                <div className="collapse-box-icon">
                  {collapseTab === 10 ? (
                    <div>
                      <img src={minusIcon} alt="Minus" />
                    </div>
                  ) : (
                    <div>
                      <img src={plusIcon} alt="Plus" />
                    </div>
                  )}
                </div>
              </div>
              {collapseTab === 10 && (
                <div className="open-tab-desc">
                  <p>{t("FAQ_ASKED_QUESTION_TAB_10_TEXT")}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageComponent;
