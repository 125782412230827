import React from "react";
import ReactivePaymentModalComponent from "./reactivePaymentModal";
import { loadStripe } from "@stripe/stripe-js/pure";
import { llcLoadScript, stripeLoadScript } from "../../../../environment";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { updateBillingMethod } from "../../../../redux/slice/updateBillingMethodSlice";
import { connect } from "react-redux";
import { getLang } from "../../../../utils/commonUtils";
import { Cookies } from "react-cookie";

loadStripe.setLoadParameters({ advancedFraudSignals: false });
const stripePromise = loadStripe(stripeLoadScript);
const llcStripePromise = loadStripe(llcLoadScript);

const ReactivePaymentModal = ({
  callUpdateBillingMethod,
  handlePaymentUpdate,
  productList,
  authenticate,
}) => {
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const options = {
    locale: getLang(lang),
  };
  return (
    <Elements
      stripe={
        authenticate.data.subscription.account === "TRACELO_LLC"
          ? llcStripePromise
          : stripePromise
      }
      options={options}
    >
      <ReactivePaymentModalComponent
        useStripe={useStripe}
        useElements={useElements}
        callUpdateBillingMethod={callUpdateBillingMethod}
        handlePaymentUpdate={handlePaymentUpdate}
        productList={productList}
      />
    </Elements>
  );
};

const mapStateToProps = (state) => {
  return {
    productList: state.getProductList.productListData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callUpdateBillingMethod: (data) => dispatch(updateBillingMethod(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactivePaymentModal);
