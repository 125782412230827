import React from 'react'
import loadingGif from "../../assets/location.gif";

const AmazonPayComponent = () => {
  return (
    <React.Fragment>
      <img src={loadingGif} className="loading-spin-image" alt="loading..." />
    </React.Fragment>
  )
}

export default AmazonPayComponent
