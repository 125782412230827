/* global gtag */
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Notify from "../../common/Notify/notify";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

const CheckoutComponent = (props) => {
  const {
    isLoading,
    setIsLoading,
    data,
    TrialCurr,
    subscriptionProductPrice,
    callsubscriptionCreate,
    subscription,
    page,
    subscriptionId,
    callgclidData,
    gclid,
    useId,
    senderNumber,
    senderNumberCountry,
    callSettingBanIp,
    ipData,
    paramas,
    payment,
    searchNumber,
    email,
    setStep,
  } = props;
  const { t } = useTranslation();
  const paymentElementOptions = {
    layout: "tabs",
    terms: {
      card: "never",
      applePay: "never",
      googlePay: "never",
      cashapp: "never",
      auBecsDebit: "never",
      bancontact: "never",
      ideal: "never",
      paypal: "never",
      sepaDebit: "never",
      sofort: "never",
      usBankAccount: "never",
    },
  };
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const navigate = useNavigate();
  const [ip, setIp] = useState("");
  const subscriptionPlan = window.location.pathname.includes("subscription");
  const pageVersion = page === "track" || page === "trace" ? "b" : "w";
  useEffect(() => {
    if (searchNumber.email && subscriptionProductPrice) {
      if (elements) {
        const paymentElement = elements.getElement(PaymentElement);
        if (paymentElement) {
          paymentElement.on("ready", () => {
            setIsLoading(false);
            setStep(2);
          });
        }
      }
    }
  }, [stripe, elements]);
  useEffect(() => {
    if (ipData && ipData.ip) {
      setIp(ipData.ip);
    }
  }, [ipData]);
  useEffect(() => {
    if (!stripe) {
      return;
    }
    let clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe, elements]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    if (payment === "amazon") {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `https://stage.tracelo.com/${lang}/amazon-pay?D=${
            searchNumber.D
          }&n=${searchNumber.n}&email=${email}&c=${"usd"}&amount=${
            window.location.pathname.includes("subscription")
              ? subscriptionProductPrice?.sent_amount
              : TrialCurr?.sent_amount
          }&price=price_1Pl7qBARglNY93qrf5yTvtKJ`,
        },
      });
    } else {
      const result = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });
      if (result.error) {
        setIsLoading(false);
        setMessage(result.error.message);
      } else {
        const apiPayload = {
          email: data.email,
          amount: window.location.pathname.includes("subscription")
            ? subscriptionProductPrice?.sent_amount
            : TrialCurr?.sent_amount,
          payment_method: result.paymentIntent.payment_method,
          price: subscriptionProductPrice.price_id,
          currency: result.paymentIntent.currency,
          subscription_id: subscriptionId,
          plan: window.location.pathname.includes("subscription")
            ? "standard"
            : "trail",
        };
        const subscriptionResult = await callsubscriptionCreate(apiPayload);
        gtag("event", "purchase", {
          transaction_id: result.paymentIntent.id,
          user_id: useId,
          page_version: pageVersion,
          purchase_amount: window.location.pathname.includes("subscription")
            ? subscriptionProductPrice?.amount
            : TrialCurr?.amount,
          currency: result.paymentIntent.currency.toUpperCase(),
          email: data.email,
          payment_method: result.paymentIntent.payment_method,
          payment_details: [result],
          postal_code: subscriptionResult.payload.data.zipcode
            ? subscriptionResult.payload.data.zipcode
            : "",
          country: subscriptionResult.payload.data.country
            ? subscriptionResult.payload.data.country
            : subscriptionResult.payload.data.country,
        });
        // Add Zaraz for trail purchase
        if (!subscriptionPlan) {
          if (window.zaraz) {
            window.zaraz.track("purchase", {
              email: data.email,
              user_id: useId,
              value: TrialCurr?.amount,
              currency_code: result.paymentIntent.currency.toUpperCase(),
              transaction_id: result.paymentIntent.id,
              postal_code: subscriptionResult.payload.data.zipcode
                ? subscriptionResult.payload.data.zipcode
                : "",
              country: subscriptionResult.payload.data.country
                ? subscriptionResult.payload.data.country
                : subscriptionResult.payload.data.country,
            });
          }
        }

        gtag("event", "conversion", {
          send_to: "AW-11387801114/zsDDCPmr7O8YEJqckLYq",
          value: subscriptionProductPrice.amount,
          currency: result.paymentIntent.currency.toUpperCase(),
          transaction_id: result.paymentIntent.id,
          email: data.email,
          postal_code: subscriptionResult.payload.data.zipcode
            ? subscriptionResult.payload.data.zipcode
            : "",
          country: subscriptionResult.payload.data.country
            ? subscriptionResult.payload.data.country
            : subscriptionResult.payload.data.country,
        });
        if (subscriptionResult.type === "subscriptionCreate/fulfilled") {
          subscriptionResult.payload.message &&
            Notify("success", t("NOTIFY_STANDARD_SUBSCRIPTION"), "");
          if (
            window.location.pathname.includes("track") ||
            window.location.pathname.includes("trace")
          ) {
            if (
              paramas &&
              typeof paramas === "object" &&
              !Object.entries(paramas)
                .map(([key, value]) => {
                  if (key === "off") {
                    return "off";
                  }
                })
                .includes("off")
            ) {
              cookie.set("banned", "true");
              const data = {
                ip_address: ip,
                status: false,
              };
              await callSettingBanIp(data);
            }
          }
          if (gclid && gclid.length > 0 && gclid[0].key && gclid[0].value) {
            const gclidData = {
              email: data.email,
              key: gclid[0].key,
              value: gclid[0].value,
              signup: "on",
            };
            callgclidData(gclidData);
          }
          cookie.set("token", subscriptionResult.payload.data.token);
          cookie.set("role", subscriptionResult.payload.data.role);
          cookie.set("email", subscriptionResult.payload.data.email);
          if (senderNumberCountry && senderNumber) {
            navigate(
              `/${lang}/dash?D=+${senderNumberCountry.replaceAll(
                " ",
                ""
              )}&n=${senderNumber}`
            );
          } else {
            navigate(`/${lang}/dash`);
          }
        } else {
          setIsLoading(false);
          Notify("error", subscriptionResult.error.message, "");
        }
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="body-modal">
      <div className="payment-details payment_modal_area">
        <div className="df-details">
          <div className="left-payment">
            <p>{t("SIGNUP_PAYMENT_TITLE")}</p>
          </div>
          <div className="right-payment pay">
            <p>
              {subscription !== "standard"
                ? TrialCurr.symbol + TrialCurr.amount
                : TrialCurr.symbol + subscriptionProductPrice.amount}
            </p>
          </div>
        </div>
        <div className="right-payment pay">
          {!(page === "track" || page === "trace") && (
            <span>
              {page === "track" || page === "trace"
                ? t("TRIAL_TIME.B")
                : t("TRIAL_TIME", {
                    amount:
                      subscriptionProductPrice.symbol +
                      subscriptionProductPrice.amount,
                  })}
            </span>
          )}
        </div>
        {(page === "track" || page === "trace") && (
          <div className="time-span">
            <span>
              {page === "track" || page === "trace"
                ? t("TRIAL_TIME.B")
                : t("TRIAL_TIME", {
                    amount:
                      subscriptionProductPrice.symbol +
                      subscriptionProductPrice.amount,
                  })}
            </span>
          </div>
        )}
      </div>
      <form id="payment-form" onSubmit={handleSubmit}>
        {PaymentElement ? (
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />
        ) : (
          <p>Loading</p>
        )}
        {message && (
          <div id="payment-message" style={{ color: "red" }}>
            {message}
          </div>
        )}
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className="hl_cta_wrap mt-4"
          type="submit"
          style={{ width: "100%" }}
        >
          <span id="button-text">{t("SIGNUP_SUBMIT")}</span>
        </button>
        {!(page === "track" || page === "trace") && (
          <span id="sign-up-term">
            {t("CHECKOUT_NOTE", {
              trialPrice: TrialCurr?.symbol + TrialCurr?.amount,
              subPrice:
                subscriptionProductPrice?.symbol +
                subscriptionProductPrice?.amount,
            })}
          </span>
        )}
      </form>
    </div>
  );
};

export default CheckoutComponent;
