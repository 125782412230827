import React, { useEffect, useState } from "react";
import AmazonPayComponent from "./amazonPay";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import { subscriptionCreateV7 } from "../../redux/slice/subscriptionCreateV7Slice";
import Notify from "../common/Notify/notify";

const AmazonPay = ({ getsubscriptionCreateV7, productList }) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const cur = cookies.get("currency");
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const subscriptionProduct = productList?.data.find(
    (item) => item.title === "standard"
  );
  const subscriptionProductPrice = subscriptionProduct?.currency_options.find(
    (item) => item.parameter === cur
  );
  const [apiCalled, setApiCalled] = useState(false);
  const createV7Subscription = async (data) => {
    const result = await getsubscriptionCreateV7(data);
    if (result.type === "subscriptionCreateV7/fulfilled") {
      result.payload.message && Notify("success", result.payload.message, "");
      cookies.set("token", result.payload.data.token);
      cookies.set("role", result.payload.data.role);
      cookies.set("email", result.payload.data.email);
      if (searchNumber.D && searchNumber.n) {
        navigate(
          `/${lang}/dash?D=+${searchNumber.D.replaceAll(" ", "")}&n=${
            searchNumber.n
          }`
        );
      } else {
        navigate(`/${lang}/dash`);
      }
    }
  };
  useEffect(() => {
    if (searchNumber.redirect_status) {
      if (searchNumber.redirect_status === "succeeded") {
        const data = {
          email: searchNumber.email,
          payment_intent_id: searchNumber.payment_intent,
          price: "price_1Pl7qCARglNY93qr4IAd0n08",
          // price: subscriptionProductPrice?.price_id,
          amount: searchNumber.amount,
        };
        if (
          data.email &&
          data.payment_intent_id &&
          data.price &&
          data.amount &&
          !apiCalled
        ) {
          setApiCalled(true);
          createV7Subscription(data);
        }
      } else {
        navigate(
          `/${lang}/sign-up?D=${searchNumber.D}&n=${searchNumber.n}&email=${searchNumber.email}`
        );
      }
    }
  }, [searchNumber, subscriptionProductPrice]);

  return <AmazonPayComponent />;
};

const mapStateToProps = (state) => {
  return {
    productList: state.getProductListSingle.productListSingleData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getsubscriptionCreateV7: (data) => dispatch(subscriptionCreateV7(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AmazonPay);
