import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiAdminFill } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";
import mainLogo from "../../assets/header/Dark-Logo.svg";
import locateSvg from "../../assets/header/settings.svg";
import { Cookies } from "react-cookie";
import { AiFillSetting } from "react-icons/ai";
import { MdPriceChange } from "react-icons/md";
import { CiWarning } from "react-icons/ci";
import { TbEdit, TbLogout } from "react-icons/tb";
import { Button, Dropdown, Input, Modal, Select, Space, Table } from "antd";
import "./currency.scss";
import { DeleteFilled as MdDeleteForever } from "@ant-design/icons";
import { currency_sumbol, renderRTLClass } from "../../utils/commonUtils";
import Notify from "../common/Notify/notify";
import { SearchOutlined } from "@ant-design/icons";
import { MailOutlined } from "@ant-design/icons";
import { useAppContextInput } from "../../App";
import { HiMenu } from "react-icons/hi";

const CurrencyComponent = (props) => {
  const { getCurrencyData, addEditCurrency, getCurrency, deleteCurrency } =
    props;
  const { t } = useTranslation();
  const cookies = new Cookies();
  const role = cookies.get("role");
  const [openAddCurrencyModal, setOpenAddCurrencyModal] = useState(false);
  const [openDeleteCurrencyModal, setOpenDeleteCurrencyModal] = useState(false);
  const [openEditCurrencyModal, setOpenEditCurrencyModal] = useState(false);
  const [editCurrencyValues, setEditCurrencyValues] = useState({});
  const [deleteCurrencyValues, setDeleteCurrencyValues] = useState({});
  const [validateErrors, setEditModalError] = useState({});
  const [addCurrencyValidateErrors, setAddModalError] = useState({});
  const lang = cookies.get("lang");
  useEffect(() => {
    const noScroll =
      openAddCurrencyModal || openDeleteCurrencyModal || openEditCurrencyModal;
    document.body.classList.toggle("no-scroll", noScroll);
    return () => document.body.classList.remove("no-scroll");
  }, [openAddCurrencyModal, openDeleteCurrencyModal, openEditCurrencyModal]);

  useEffect(() => {
    const noScroll =
      openAddCurrencyModal || openDeleteCurrencyModal || openEditCurrencyModal;
    document.body.classList.toggle("no-scroll", noScroll);
    return () => document.body.classList.remove("no-scroll");
  }, [openAddCurrencyModal, openDeleteCurrencyModal, openEditCurrencyModal]);
  //   Table Data
  const handleEditModalOpen = (prop) => {
    setOpenEditCurrencyModal(true);
    const editCurrency = getCurrencyData?.data.find((item) => {
      return item.parameter === prop;
    });
    setEditCurrencyValues(editCurrency);
  };
  const handleDeleteModalOpen = (prop) => {
    setOpenDeleteCurrencyModal(true);
    const deleteCurrency = getCurrencyData?.data.find((item) => {
      return item.parameter === prop;
    });
    setDeleteCurrencyValues(deleteCurrency);
  };
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => (
              // eslint-disable-next-line
              clearFilters && handleReset(clearFilters),
              confirm({
                closeDropdown: false,
              })
            )}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <div className="search-btn">
        <SearchOutlined
          style={{
            color: filtered ? "#1677ff" : undefined,
          }}
        />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });
  const columns = [
    {
      title: "Currency Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Currency code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "C parameter",
      dataIndex: "cParameter",
      key: "cParameter",
    },
    {
      title: "Trial Pricing",
      dataIndex: "trial",
      key: "trial",
    },
    {
      title: "Subscription Pricing",
      dataIndex: "subscription",
      key: "subscription",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text) => (
        <div className="actions">
          <TbEdit
            className="action-svg"
            onClick={() => handleEditModalOpen(text)}
          />
          <MdDeleteForever
            className="action-svg red"
            onClick={() => handleDeleteModalOpen(text)}
          />
        </div>
      ),
    },
  ];

  const data =
    Array.isArray(getCurrencyData?.data) &&
    getCurrencyData?.data?.map((item, index) => ({
      key: (index + 1).toString(),
      _id: item._id,
      name: item.name,
      code: item.code,
      cParameter: item.parameter,
      trial: item.trial,
      subscription: item.full,
      action: item.parameter,
    }));
  const symbol_option = currency_sumbol.map((item) => {
    const array = {
      label: item,
      value: item,
    };
    return array;
  });
  const [editDataCollaction, setEditDataCollaction] =
    useState(editCurrencyValues);
  useEffect(() => {
    setEditDataCollaction(editCurrencyValues);
  }, [editCurrencyValues, openEditCurrencyModal]);
  const handleChangeEditCurrency = (event) => {
    const { name, value } = event.target;
    setEditDataCollaction((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleChangeEditCurrencySymbol = (event) => {
    setEditDataCollaction((prevState) => ({ ...prevState, symbol: event }));
  };
  const handleEdit = async () => {
    const errors = {};

    if (!editDataCollaction.name) {
      errors.name = "Currency Name is required";
    }
    if (!editDataCollaction.symbol) {
      errors.symbol = "Currency Symbol is required";
    }
    if (!editDataCollaction.parameter) {
      errors.parameter = "C parameter is required";
    }
    if (!editDataCollaction.trial) {
      errors.trial = "Trial Pricing is required";
    }
    if (!editDataCollaction.full) {
      errors.full = "Subscription Pricing is required";
    }
    setEditModalError(errors);
    if (Object.keys(errors).length === 0) {
      const data = {
        name: editDataCollaction.name,
        code: editDataCollaction.code,
        parameter: editDataCollaction.parameter,
        symbol: editDataCollaction.symbol,
        trial: editDataCollaction.trial,
        full: editDataCollaction.full,
      };
      const result = await addEditCurrency(data);
      if (result.type === "addEditCurrency/fulfilled") {
        getCurrency();
        Notify("success", result.payload.message, "");
        setOpenAddCurrencyModal(false);
        setOpenDeleteCurrencyModal(false);
        setOpenEditCurrencyModal(false);
      } else {
        Notify("error", result.error.message, "");
      }
    }
  };
  //   Add New Currency Functionally
  const [addCurrencyValues, setAddCurrencyValues] = useState({
    name: "",
    code: "",
    parameter: "",
    symbol: "",
    trial: "",
    full: "",
  });
  const handleChangeAddCurrency = (event) => {
    const { name, value } = event.target;
    setAddCurrencyValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChangeAddCurrencySymbol = (event) => {
    setAddCurrencyValues((prevState) => ({
      ...prevState,
      symbol: event,
    }));
  };
  const handleAddNewCurrency = async () => {
    const errors = {};

    if (!addCurrencyValues.name) {
      errors.name = "Currency Name is required";
    }
    if (!addCurrencyValues.symbol) {
      errors.symbol = "Currency Symbol is required";
    }
    if (!addCurrencyValues.code) {
      errors.code = "Currency code is required";
    }
    if (!addCurrencyValues.parameter) {
      errors.parameter = "C parameter is required";
    }
    if (!addCurrencyValues.trial) {
      errors.trial = "Trial Pricing is required";
    }
    if (!addCurrencyValues.full) {
      errors.full = "Subscription Pricing is required";
    }
    setAddModalError(errors);
    if (Object.keys(errors).length === 0) {
      const data = {
        name: addCurrencyValues.name,
        code: addCurrencyValues.code,
        parameter: addCurrencyValues.parameter,
        symbol: addCurrencyValues.symbol,
        trial: addCurrencyValues.trial,
        full: addCurrencyValues.full,
      };
      const result = await addEditCurrency(data);
      if (result.type === "addEditCurrency/fulfilled") {
        getCurrency();
        Notify("success", result.payload.message, "");
        setOpenAddCurrencyModal(false);
        setOpenDeleteCurrencyModal(false);
        setOpenEditCurrencyModal(false);
        setAddCurrencyValues({
          name: "",
          code: "",
          parameter: "",
          symbol: "",
          trial: "",
          full: "",
        });
      } else {
        Notify("error", result.error.message, "");
      }
    }
  };
  // Delete Currency
  // deleteCurrencyValues
  // handleDeleteCurrency
  const handleCallDelete = async () => {
    const result = await deleteCurrency(deleteCurrencyValues.parameter);
    if (result.type === "deleteCurrency/fulfilled") {
      getCurrency();
      Notify("success", result.payload.message, "");
      setOpenDeleteCurrencyModal(false);
    } else {
      Notify("error", result.error.message, "");
    }
  };

  //   Close all modals
  const handleCloseModal = () => {
    setOpenAddCurrencyModal(false);
    setOpenDeleteCurrencyModal(false);
    setOpenEditCurrencyModal(false);
    setEditModalError({});
    setAddModalError({});
    setAddCurrencyValues({
      name: "",
      code: "",
      parameter: "",
      symbol: "",
      trial: "",
      full: "",
    });
  };
  // hum-burger-menu
  const { handleLogout } = useAppContextInput();
  const items = [
    {
      label: (
        <Link to={`/${lang}/dash`}>
          <img src={locateSvg} alt="" width={20} height={20} />
          <span>{t("DASHBOARD")}</span>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link to={`/${lang}/settings`}>
          <AiFillSetting />
          <span>{t("FOOTER_ACCOUNT")}</span>
        </Link>
      ),
      key: "1",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/email-test`}>
          <MailOutlined />
          <span>{t("TEST_EMAIL ")}</span>
        </Link>
      ),
      key: "2",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/currency`} className="active">
          <MdPriceChange />
          <span>{t("CURRENCIES ")}</span>
        </Link>
      ),
      key: "3",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/admin`}>
          <RiAdminFill />
          <span>{t("ADMINS")}</span>
        </Link>
      ),
      key: "4",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/users`}>
          <FaUsers />
          <span>{t("DASHBOARD_HEADER_USER")}</span>
        </Link>
      ),
      key: "5",
    },
    {
      label: (
        <Link to={`#`} onClick={handleLogout}>
          <TbLogout />
          <span>{t("LOGOUT")}</span>
        </Link>
      ),
      key: "6",
    },
  ];
  return (
    <div className="dashnoard-main-section">
      <div className="container">
        <div className="dashboard-header">
          <div className="main-logo-img">
            <Link to={`/${lang}/dash`}>
              <img src={mainLogo} alt="" />
            </Link>
          </div>
          <div className="hum-burger-menu">
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              placement="bottomRight"
              overlayClassName="dashboard-header-menu-mobile"
            >
              <span onClick={(e) => e.preventDefault()}>
                <Space>
                  <HiMenu />
                </Space>
              </span>
            </Dropdown>
          </div>
        </div>
        <div className="currency_data">
          <div className="add_currency_btn">
            <button onClick={() => setOpenAddCurrencyModal(true)}>
              <MdPriceChange />
              <span>
                Add Currency
                {/* {t("DASHBOARD_HEADER_ADMIN")} */}
              </span>
            </button>
          </div>

          <Table
            columns={columns}
            dataSource={data}
            pagination={data.length > 10}
          />
        </div>
      </div>
      {/* Add Currency Modal Start */}
      <Modal
        open={openAddCurrencyModal}
        onCancel={handleCloseModal}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={renderRTLClass()}
      >
        <div className="login-modal-section">
          <div className="login-modal-title padd">
            <p>Add Currency</p>
          </div>
          <div className="form_modal_add_currency">
            <div className="input">
              <label>Currency Name</label>
              <Input
                name="name"
                onChange={handleChangeAddCurrency}
                value={addCurrencyValues.name}
              />
              {addCurrencyValidateErrors.name && (
                <p>{addCurrencyValidateErrors.name}</p>
              )}
            </div>
            <div className="add-user-df">
              <div className="input">
                <label>Currency Symbol</label>
                <Select
                  showSearch
                  defaultValue={addCurrencyValues.symbol}
                  value={addCurrencyValues.symbol}
                  onChange={handleChangeAddCurrencySymbol}
                  options={symbol_option}
                ></Select>
                {addCurrencyValidateErrors.symbol && (
                  <p>{addCurrencyValidateErrors.symbol}</p>
                )}
              </div>
              <div className="input">
                <label>Currency code</label>
                <Input
                  name="code"
                  onChange={handleChangeAddCurrency}
                  value={addCurrencyValues.code}
                />
                {addCurrencyValidateErrors.code && (
                  <p>{addCurrencyValidateErrors.code}</p>
                )}
              </div>
              <div className="input">
                <label>C parameter</label>
                <Input
                  name="parameter"
                  onChange={handleChangeAddCurrency}
                  value={addCurrencyValues.parameter}
                />
                {addCurrencyValidateErrors.parameter && (
                  <p>{addCurrencyValidateErrors.parameter}</p>
                )}
              </div>
            </div>
            <div className="add-user-df">
              <div className="input">
                <label>Trial Pricing</label>
                <Input
                  name="trial"
                  type="number"
                  onChange={handleChangeAddCurrency}
                  value={addCurrencyValues.trial}
                />
                {addCurrencyValidateErrors.trial && (
                  <p>{addCurrencyValidateErrors.trial}</p>
                )}
              </div>
              <div className="input">
                <label>Subscription Pricing</label>
                <Input
                  name="full"
                  type="number"
                  onChange={handleChangeAddCurrency}
                  value={addCurrencyValues.full}
                />
                {addCurrencyValidateErrors.full && (
                  <p>{addCurrencyValidateErrors.full}</p>
                )}
              </div>
            </div>
            <div className="submit-btn">
              <button onClick={handleAddNewCurrency}>Submit</button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Add Currency Modal Stop */}
      {/* Delete Currency Modal Start */}
      <Modal
        open={openDeleteCurrencyModal}
        onCancel={handleCloseModal}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={renderRTLClass()}
      >
        <div className="user-delete-confirm-model">
          <CiWarning size={90} color="#ff4d4f" />

          <div className="user-delete-text">
            Are you certain that you wish to delete this currency?
            {/* {t("ADMIN_DELETE_CONFIRM_TEXT")} */}
          </div>
          <div>
            <Button
              type="primary"
              danger
              className="user-delete-button"
              onClick={handleCloseModal}
            >
              {t("ADMIN_DELETE_CANCEL")}
            </Button>
            <Button
              type="primary"
              className="user-delete-yes-button"
              onClick={() => handleCallDelete()}
            >
              {t("ADMIN_DELETE_YES")}
            </Button>
          </div>
        </div>
      </Modal>
      {/* Delete Currency Modal Stop */}
      {/* Edit Currency Modal Start */}
      <Modal
        open={openEditCurrencyModal}
        onCancel={handleCloseModal}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={renderRTLClass()}
      >
        <div className="login-modal-section">
          <div className="login-modal-title padd">
            <p>Edit Currency</p>
          </div>
          <div className="form_modal_add_currency">
            <div className="input">
              <label>Currency Name</label>
              <Input
                name="name"
                defaultValue={editDataCollaction.name}
                value={editDataCollaction.name}
                onChange={handleChangeEditCurrency}
              />
              {validateErrors.name && <p>{validateErrors.name}</p>}
            </div>
            <div className="add-user-df">
              <div className="input">
                <label>Currency Symbol</label>
                <Select
                  defaultValue={editDataCollaction.symbol}
                  value={editDataCollaction.symbol}
                  onChange={handleChangeEditCurrencySymbol}
                  options={symbol_option}
                ></Select>
                {validateErrors.symbol && <p>{validateErrors.symbol}</p>}
              </div>
              <div className="input">
                <label>Currency code</label>
                <Input
                  name="code"
                  defaultValue={editDataCollaction.code}
                  value={editDataCollaction.code}
                  disabled
                  //   onChange={handleAddUserDetail}
                  //   value={userData.first_name}
                />
              </div>
              <div className="input">
                <label>C parameter</label>
                <Input
                  name="parameter"
                  defaultValue={editDataCollaction.parameter}
                  value={editDataCollaction.parameter}
                  onChange={handleChangeEditCurrency}
                  //   value={userData.last_name}
                />
                {validateErrors.parameter && <p>{validateErrors.parameter}</p>}
              </div>
            </div>
            <div className="add-user-df">
              <div className="input">
                <label>Trial Pricing</label>
                <Input
                  name="trial"
                  type="number"
                  defaultValue={editDataCollaction.trial}
                  value={editDataCollaction.trial}
                  onChange={handleChangeEditCurrency}
                  //   value={userData.first_name}
                />
                {validateErrors.trial && <p>{validateErrors.trial}</p>}
              </div>
              <div className="input">
                <label>Subscription Pricing</label>
                <Input
                  name="full"
                  type="number"
                  defaultValue={editDataCollaction.full}
                  value={editDataCollaction.full}
                  onChange={handleChangeEditCurrency}
                  //   value={userData.last_name}
                />
                {validateErrors.full && <p>{validateErrors.full}</p>}
              </div>
            </div>
            <div className="submit-btn">
              <button onClick={handleEdit}>Update</button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Edit Currency Modal Stop */}
    </div>
  );
};

export default CurrencyComponent;
