import React, { useState, useEffect } from "react";
import "./pricing.scss";
import { useTranslation } from "react-i18next";
import specialOfferImg from "../../assets/home/special-offer.png";
import { Select, Space } from "antd";
import { Cookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useAppContextInput } from "../../App";
import useScrollToTop from "../customHook/useScrollToTop";

const PricingComponent = (props) => {
  const { productList } = props;
  const cookie = new Cookies();
  const cur = cookie.get("currency");
  const lang = cookie.get("lang");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [pricing, setPricing] = useState(cur);
  useEffect(() => {
    if (cur) {
      setPricing(cur);
    }
  }, [cur]);
  const handleChange = (event) => {
    setPricing(event);
    cookie.set("currency", event);
    navigate(`/${lang}/pricing?c=${event}`);
  };
  useScrollToTop();

  const trialProduct = productList?.data.find((item) => item.title === "trial");
  const findPrice = trialProduct?.currency_options.find(
    (item) => item.parameter === pricing
  );

  const subscriptionProduct = productList?.data.find(
    (item) => item.title === "standard"
  );
  const subscriptionCurr = subscriptionProduct?.currency_options.find(
    (item) => item.parameter === cur
  );
  const options = trialProduct?.currency_options.map((item) => ({
    value: item.parameter,
    label: item.code,
  }));

  const focusInput = (e) => {
    e.preventDefault();
    navigate(`/${lang}`);
  };
  return (
    <div className="pricing-main-section pricing_page__wrapper">
      <div className="back-grad-title">
        <div className="pricing-main-title">{t("PRICING_TITLE")}</div>
      </div>
      <div className="container">
        <div className="home-special-offer">
          <div className="specialOffer-container">
            <div className="special-offerbox-partition">
              <div className="img-box">
                <div className="box-heading">
                  <p>{t("PRICING_SPECIAL_OFFER")}</p>
                </div>
                <div className="desc-special">
                  <p>
                    {t("PRICING_OFFER", {
                      price: findPrice?.symbol + findPrice?.amount,
                    })}
                  </p>
                </div>
                <div className="desc-special-span">
                  <p>{t("PRICING_TEXT")}</p>
                </div>
                <div className="backGroundImg">
                  <img src={specialOfferImg} alt="BackGround" />
                </div>
              </div>
              <div className="right-contant">
                <div className="pricing-title ">
                  <div className="pricing">
                    <span style={{ marginRight: "10px" }}>
                      {t("CURRENCY_TITLE")}
                    </span>
                    <Space wrap>
                      <Select
                        defaultValue={findPrice?.code}
                        value={findPrice?.code}
                        style={{
                          width: 150,
                        }}
                        onChange={handleChange}
                        options={options}
                      />
                    </Space>
                  </div>
                </div>
                <div className="img-box-2">
                  <div className="box-heading">
                    <p>{t("PRICING_TRIAL_TAG")}</p>
                  </div>
                  <div className="desc-special">
                    <h3>{findPrice?.symbol + findPrice?.amount}</h3>
                    <p>{t("PRICING_TRIAL_TIME")}</p>
                  </div>
                  <div className="desc-special-span">
                    <p>
                      {t("PRICING_TRIAL_TEXT", {
                        price: findPrice?.symbol + subscriptionCurr?.amount,
                      })}
                    </p>
                  </div>
                  <div className="bottom-btn">
                    <Link to={`#`} onClick={focusInput}>
                      <button className="hl_cta_wrap">
                        {t("PRICING_TRIAL_START")}
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="img-box-3">
                  <div className="box-heading">
                    <p>{t("PRICING_SUBSCRIPTION_TAG")}</p>
                  </div>
                  <div className="desc-special">
                    <h3>{findPrice?.symbol + subscriptionCurr?.amount}</h3>
                    <p>{t("PRICING_SUBSCRIPTION_TIME")}</p>
                  </div>
                  <div className="desc-special-span">
                    <p>{t("PRICING_SUBSCRIPTION_TEXT")}</p>
                  </div>
                  <div className="bottom-btn">
                    <Link to={`#`} onClick={focusInput}>
                      <button className="hl_cta_wrap-white">
                        {t("PRICING_SUBSCRIPTION_START")}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
